
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * ManagerRight enum
 */
class ManagerRight {
  /**
   * Initialize enum with a given value
   * @param {ManagerRight|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof ManagerRight) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!ManagerRight}
   * @param {!ManagerRight} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ManagerRight)) {
      throw new TypeError('Instance of ManagerRight is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!ManagerRight}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!ManagerRight}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === ManagerRight.HOSTS_VIEW.value) {
      return 'HOSTS_VIEW'
    }
    if (this.value === ManagerRight.HOSTS_EDIT.value) {
      return 'HOSTS_EDIT'
    }
    if (this.value === ManagerRight.HOSTS_CREATE.value) {
      return 'HOSTS_CREATE'
    }
    if (this.value === ManagerRight.HOSTS_DELETE.value) {
      return 'HOSTS_DELETE'
    }
    if (this.value === ManagerRight.MODULES_VIEW.value) {
      return 'MODULES_VIEW'
    }
    if (this.value === ManagerRight.MODULES_EDIT.value) {
      return 'MODULES_EDIT'
    }
    if (this.value === ManagerRight.MODULES_CREATE.value) {
      return 'MODULES_CREATE'
    }
    if (this.value === ManagerRight.MODULES_DELETE.value) {
      return 'MODULES_DELETE'
    }
    if (this.value === ManagerRight.MODULES_SYSTEM_INFO_VIEW.value) {
      return 'MODULES_SYSTEM_INFO_VIEW'
    }
    if (this.value === ManagerRight.TIMEZONES_VIEW.value) {
      return 'TIMEZONES_VIEW'
    }
    if (this.value === ManagerRight.TIMEZONES_EDIT.value) {
      return 'TIMEZONES_EDIT'
    }
    if (this.value === ManagerRight.TIMEZONES_CREATE.value) {
      return 'TIMEZONES_CREATE'
    }
    if (this.value === ManagerRight.TIMEZONES_DELETE.value) {
      return 'TIMEZONES_DELETE'
    }
    if (this.value === ManagerRight.CURRENCY_GROUPS_VIEW.value) {
      return 'CURRENCY_GROUPS_VIEW'
    }
    if (this.value === ManagerRight.CURRENCY_GROUPS_EDIT.value) {
      return 'CURRENCY_GROUPS_EDIT'
    }
    if (this.value === ManagerRight.CURRENCY_GROUPS_CREATE.value) {
      return 'CURRENCY_GROUPS_CREATE'
    }
    if (this.value === ManagerRight.CURRENCY_GROUPS_DELETE.value) {
      return 'CURRENCY_GROUPS_DELETE'
    }
    if (this.value === ManagerRight.CURRENCIES_VIEW.value) {
      return 'CURRENCIES_VIEW'
    }
    if (this.value === ManagerRight.CURRENCIES_EDIT.value) {
      return 'CURRENCIES_EDIT'
    }
    if (this.value === ManagerRight.CURRENCIES_CREATE.value) {
      return 'CURRENCIES_CREATE'
    }
    if (this.value === ManagerRight.CURRENCIES_DELETE.value) {
      return 'CURRENCIES_DELETE'
    }
    if (this.value === ManagerRight.SYMBOL_GROUPS_VIEW.value) {
      return 'SYMBOL_GROUPS_VIEW'
    }
    if (this.value === ManagerRight.SYMBOL_GROUPS_EDIT.value) {
      return 'SYMBOL_GROUPS_EDIT'
    }
    if (this.value === ManagerRight.SYMBOL_GROUPS_CREATE.value) {
      return 'SYMBOL_GROUPS_CREATE'
    }
    if (this.value === ManagerRight.SYMBOL_GROUPS_DELETE.value) {
      return 'SYMBOL_GROUPS_DELETE'
    }
    if (this.value === ManagerRight.SYMBOLS_VIEW.value) {
      return 'SYMBOLS_VIEW'
    }
    if (this.value === ManagerRight.SYMBOLS_EDIT.value) {
      return 'SYMBOLS_EDIT'
    }
    if (this.value === ManagerRight.SYMBOLS_CREATE.value) {
      return 'SYMBOLS_CREATE'
    }
    if (this.value === ManagerRight.SYMBOLS_DELETE.value) {
      return 'SYMBOLS_DELETE'
    }
    if (this.value === ManagerRight.SYMBOLS_SEND_TICKS.value) {
      return 'SYMBOLS_SEND_TICKS'
    }
    if (this.value === ManagerRight.SWAPS_IMPORT.value) {
      return 'SWAPS_IMPORT'
    }
    if (this.value === ManagerRight.SWAPS_EXPORT.value) {
      return 'SWAPS_EXPORT'
    }
    if (this.value === ManagerRight.COMMISSION_PROFILES_VIEW.value) {
      return 'COMMISSION_PROFILES_VIEW'
    }
    if (this.value === ManagerRight.COMMISSION_PROFILES_EDIT.value) {
      return 'COMMISSION_PROFILES_EDIT'
    }
    if (this.value === ManagerRight.COMMISSION_PROFILES_CREATE.value) {
      return 'COMMISSION_PROFILES_CREATE'
    }
    if (this.value === ManagerRight.COMMISSION_PROFILES_DELETE.value) {
      return 'COMMISSION_PROFILES_DELETE'
    }
    if (this.value === ManagerRight.MARKUP_PROFILES_VIEW.value) {
      return 'MARKUP_PROFILES_VIEW'
    }
    if (this.value === ManagerRight.MARKUP_PROFILES_EDIT.value) {
      return 'MARKUP_PROFILES_EDIT'
    }
    if (this.value === ManagerRight.MARKUP_PROFILES_CREATE.value) {
      return 'MARKUP_PROFILES_CREATE'
    }
    if (this.value === ManagerRight.MARKUP_PROFILES_DELETE.value) {
      return 'MARKUP_PROFILES_DELETE'
    }
    if (this.value === ManagerRight.MANAGER_GROUPS_VIEW.value) {
      return 'MANAGER_GROUPS_VIEW'
    }
    if (this.value === ManagerRight.MANAGER_GROUPS_EDIT.value) {
      return 'MANAGER_GROUPS_EDIT'
    }
    if (this.value === ManagerRight.MANAGER_GROUPS_CREATE.value) {
      return 'MANAGER_GROUPS_CREATE'
    }
    if (this.value === ManagerRight.MANAGER_GROUPS_DELETE.value) {
      return 'MANAGER_GROUPS_DELETE'
    }
    if (this.value === ManagerRight.MANAGERS_VIEW.value) {
      return 'MANAGERS_VIEW'
    }
    if (this.value === ManagerRight.MANAGERS_EDIT.value) {
      return 'MANAGERS_EDIT'
    }
    if (this.value === ManagerRight.MANAGERS_CREATE.value) {
      return 'MANAGERS_CREATE'
    }
    if (this.value === ManagerRight.MANAGERS_DELETE.value) {
      return 'MANAGERS_DELETE'
    }
    if (this.value === ManagerRight.PLATFORMS_VIEW.value) {
      return 'PLATFORMS_VIEW'
    }
    if (this.value === ManagerRight.PLATFORMS_EDIT.value) {
      return 'PLATFORMS_EDIT'
    }
    if (this.value === ManagerRight.PLATFORMS_CREATE.value) {
      return 'PLATFORMS_CREATE'
    }
    if (this.value === ManagerRight.PLATFORMS_DELETE.value) {
      return 'PLATFORMS_DELETE'
    }
    if (this.value === ManagerRight.ACCOUNT_GROUPS_VIEW.value) {
      return 'ACCOUNT_GROUPS_VIEW'
    }
    if (this.value === ManagerRight.ACCOUNT_GROUPS_EDIT.value) {
      return 'ACCOUNT_GROUPS_EDIT'
    }
    if (this.value === ManagerRight.ACCOUNT_GROUPS_CREATE.value) {
      return 'ACCOUNT_GROUPS_CREATE'
    }
    if (this.value === ManagerRight.ACCOUNT_GROUPS_DELETE.value) {
      return 'ACCOUNT_GROUPS_DELETE'
    }
    if (this.value === ManagerRight.ACCOUNTS_VIEW.value) {
      return 'ACCOUNTS_VIEW'
    }
    if (this.value === ManagerRight.ACCOUNTS_EDIT.value) {
      return 'ACCOUNTS_EDIT'
    }
    if (this.value === ManagerRight.ACCOUNTS_CREATE.value) {
      return 'ACCOUNTS_CREATE'
    }
    if (this.value === ManagerRight.ACCOUNTS_DELETE.value) {
      return 'ACCOUNTS_DELETE'
    }
    if (this.value === ManagerRight.ACCOUNTS_DEPOSIT.value) {
      return 'ACCOUNTS_DEPOSIT'
    }
    if (this.value === ManagerRight.ACCOUNTS_CREDIT.value) {
      return 'ACCOUNTS_CREDIT'
    }
    if (this.value === ManagerRight.ACCOUNTS_TRANSFER_MONEY.value) {
      return 'ACCOUNTS_TRANSFER_MONEY'
    }
    if (this.value === ManagerRight.ACCOUNTS_PERFORM_ROLLOVER.value) {
      return 'ACCOUNTS_PERFORM_ROLLOVER'
    }
    if (this.value === ManagerRight.ACCOUNTS_PERSONAL_DETAILS_VIEW.value) {
      return 'ACCOUNTS_PERSONAL_DETAILS_VIEW'
    }
    if (this.value === ManagerRight.ORDERS_VIEW.value) {
      return 'ORDERS_VIEW'
    }
    if (this.value === ManagerRight.ORDERS_EDIT.value) {
      return 'ORDERS_EDIT'
    }
    if (this.value === ManagerRight.ORDERS_CREATE.value) {
      return 'ORDERS_CREATE'
    }
    if (this.value === ManagerRight.ORDERS_DELETE.value) {
      return 'ORDERS_DELETE'
    }
    if (this.value === ManagerRight.ORDERS_ADJUST_DIVIDENDS.value) {
      return 'ORDERS_ADJUST_DIVIDENDS'
    }
    if (this.value === ManagerRight.ORDERS_SPLIT.value) {
      return 'ORDERS_SPLIT'
    }
    if (this.value === ManagerRight.POSITIONS_VIEW.value) {
      return 'POSITIONS_VIEW'
    }
    if (this.value === ManagerRight.POSITIONS_EDIT.value) {
      return 'POSITIONS_EDIT'
    }
    if (this.value === ManagerRight.POSITIONS_CREATE.value) {
      return 'POSITIONS_CREATE'
    }
    if (this.value === ManagerRight.POSITIONS_DELETE.value) {
      return 'POSITIONS_DELETE'
    }
    if (this.value === ManagerRight.TRADE_HISTORY_VIEW.value) {
      return 'TRADE_HISTORY_VIEW'
    }
    if (this.value === ManagerRight.TRADE_HISTORY_EDIT.value) {
      return 'TRADE_HISTORY_EDIT'
    }
    if (this.value === ManagerRight.TRADE_HISTORY_DELETE.value) {
      return 'TRADE_HISTORY_DELETE'
    }
    if (this.value === ManagerRight.CALENDAR_RULES_VIEW.value) {
      return 'CALENDAR_RULES_VIEW'
    }
    if (this.value === ManagerRight.CALENDAR_RULES_EDIT.value) {
      return 'CALENDAR_RULES_EDIT'
    }
    if (this.value === ManagerRight.CALENDAR_RULES_CREATE.value) {
      return 'CALENDAR_RULES_CREATE'
    }
    if (this.value === ManagerRight.CALENDAR_RULES_DELETE.value) {
      return 'CALENDAR_RULES_DELETE'
    }
    if (this.value === ManagerRight.OFF_TIME_RULES_VIEW.value) {
      return 'OFF_TIME_RULES_VIEW'
    }
    if (this.value === ManagerRight.OFF_TIME_RULES_EDIT.value) {
      return 'OFF_TIME_RULES_EDIT'
    }
    if (this.value === ManagerRight.OFF_TIME_RULES_CREATE.value) {
      return 'OFF_TIME_RULES_CREATE'
    }
    if (this.value === ManagerRight.OFF_TIME_RULES_DELETE.value) {
      return 'OFF_TIME_RULES_DELETE'
    }
    if (this.value === ManagerRight.TRADING_ROUTE_RULES_VIEW.value) {
      return 'TRADING_ROUTE_RULES_VIEW'
    }
    if (this.value === ManagerRight.TRADING_ROUTE_RULES_EDIT.value) {
      return 'TRADING_ROUTE_RULES_EDIT'
    }
    if (this.value === ManagerRight.TRADING_ROUTE_RULES_CREATE.value) {
      return 'TRADING_ROUTE_RULES_CREATE'
    }
    if (this.value === ManagerRight.TRADING_ROUTE_RULES_DELETE.value) {
      return 'TRADING_ROUTE_RULES_DELETE'
    }
    if (this.value === ManagerRight.SYMBOL_MAPS_VIEW.value) {
      return 'SYMBOL_MAPS_VIEW'
    }
    if (this.value === ManagerRight.SYMBOL_MAPS_EDIT.value) {
      return 'SYMBOL_MAPS_EDIT'
    }
    if (this.value === ManagerRight.SYMBOL_MAPS_CREATE.value) {
      return 'SYMBOL_MAPS_CREATE'
    }
    if (this.value === ManagerRight.SYMBOL_MAPS_DELETE.value) {
      return 'SYMBOL_MAPS_DELETE'
    }
    if (this.value === ManagerRight.GATEWAYS_VIEW.value) {
      return 'GATEWAYS_VIEW'
    }
    if (this.value === ManagerRight.GATEWAYS_EDIT.value) {
      return 'GATEWAYS_EDIT'
    }
    if (this.value === ManagerRight.GATEWAYS_CREATE.value) {
      return 'GATEWAYS_CREATE'
    }
    if (this.value === ManagerRight.GATEWAYS_DELETE.value) {
      return 'GATEWAYS_DELETE'
    }
    if (this.value === ManagerRight.DATA_FEEDS_VIEW.value) {
      return 'DATA_FEEDS_VIEW'
    }
    if (this.value === ManagerRight.DATA_FEEDS_EDIT.value) {
      return 'DATA_FEEDS_EDIT'
    }
    if (this.value === ManagerRight.DATA_FEEDS_CREATE.value) {
      return 'DATA_FEEDS_CREATE'
    }
    if (this.value === ManagerRight.DATA_FEEDS_DELETE.value) {
      return 'DATA_FEEDS_DELETE'
    }
    if (this.value === ManagerRight.MAIL_SERVERS_VIEW.value) {
      return 'MAIL_SERVERS_VIEW'
    }
    if (this.value === ManagerRight.MAIL_SERVERS_EDIT.value) {
      return 'MAIL_SERVERS_EDIT'
    }
    if (this.value === ManagerRight.MAIL_SERVERS_CREATE.value) {
      return 'MAIL_SERVERS_CREATE'
    }
    if (this.value === ManagerRight.MAIL_SERVERS_DELETE.value) {
      return 'MAIL_SERVERS_DELETE'
    }
    if (this.value === ManagerRight.TICK_HISTORY_VIEW.value) {
      return 'TICK_HISTORY_VIEW'
    }
    if (this.value === ManagerRight.CHART_BARS_VIEW.value) {
      return 'CHART_BARS_VIEW'
    }
    if (this.value === ManagerRight.CHART_BARS_EDIT.value) {
      return 'CHART_BARS_EDIT'
    }
    if (this.value === ManagerRight.CHART_BARS_IMPORT.value) {
      return 'CHART_BARS_IMPORT'
    }
    if (this.value === ManagerRight.SESSIONS_VIEW.value) {
      return 'SESSIONS_VIEW'
    }
    if (this.value === ManagerRight.SESSIONS_DROP.value) {
      return 'SESSIONS_DROP'
    }
    if (this.value === ManagerRight.LEVERAGE_PROFILE_VIEW.value) {
      return 'LEVERAGE_PROFILE_VIEW'
    }
    if (this.value === ManagerRight.LEVERAGE_PROFILE_EDIT.value) {
      return 'LEVERAGE_PROFILE_EDIT'
    }
    if (this.value === ManagerRight.LEVERAGE_PROFILE_CREATE.value) {
      return 'LEVERAGE_PROFILE_CREATE'
    }
    if (this.value === ManagerRight.LEVERAGE_PROFILE_DELETE.value) {
      return 'LEVERAGE_PROFILE_DELETE'
    }
    if (this.value === ManagerRight.MARGIN_RATE_PROFILE_VIEW.value) {
      return 'MARGIN_RATE_PROFILE_VIEW'
    }
    if (this.value === ManagerRight.MARGIN_RATE_PROFILE_EDIT.value) {
      return 'MARGIN_RATE_PROFILE_EDIT'
    }
    if (this.value === ManagerRight.MARGIN_RATE_PROFILE_CREATE.value) {
      return 'MARGIN_RATE_PROFILE_CREATE'
    }
    if (this.value === ManagerRight.MARGIN_RATE_PROFILE_DELETE.value) {
      return 'MARGIN_RATE_PROFILE_DELETE'
    }
    if (this.value === ManagerRight.NEWS_PROFILE_VIEW.value) {
      return 'NEWS_PROFILE_VIEW'
    }
    if (this.value === ManagerRight.NEWS_PROFILE_EDIT.value) {
      return 'NEWS_PROFILE_EDIT'
    }
    if (this.value === ManagerRight.NEWS_PROFILE_CREATE.value) {
      return 'NEWS_PROFILE_CREATE'
    }
    if (this.value === ManagerRight.NEWS_PROFILE_DELETE.value) {
      return 'NEWS_PROFILE_DELETE'
    }
    if (this.value === ManagerRight.EMAIL_TEMPLATE_VIEW.value) {
      return 'EMAIL_TEMPLATE_VIEW'
    }
    if (this.value === ManagerRight.EMAIL_TEMPLATE_EDIT.value) {
      return 'EMAIL_TEMPLATE_EDIT'
    }
    if (this.value === ManagerRight.EMAIL_TEMPLATE_CREATE.value) {
      return 'EMAIL_TEMPLATE_CREATE'
    }
    if (this.value === ManagerRight.EMAIL_TEMPLATE_DELETE.value) {
      return 'EMAIL_TEMPLATE_DELETE'
    }
    if (this.value === ManagerRight.JOURNAL_VIEW.value) {
      return 'JOURNAL_VIEW'
    }
    if (this.value === ManagerRight.CLIENT_REPORTS_VIEW.value) {
      return 'CLIENT_REPORTS_VIEW'
    }
    if (this.value === ManagerRight.CLIENT_REPORTS_SEND.value) {
      return 'CLIENT_REPORTS_SEND'
    }
    if (this.value === ManagerRight.PERSON_VIEW.value) {
      return 'PERSON_VIEW'
    }
    if (this.value === ManagerRight.PERSON_EDIT.value) {
      return 'PERSON_EDIT'
    }
    if (this.value === ManagerRight.PERSON_CREATE.value) {
      return 'PERSON_CREATE'
    }
    if (this.value === ManagerRight.SECURITY_SETTINGS_VIEW.value) {
      return 'SECURITY_SETTINGS_VIEW'
    }
    if (this.value === ManagerRight.SECURITY_SETTINGS_EDIT.value) {
      return 'SECURITY_SETTINGS_EDIT'
    }
    if (this.value === ManagerRight.SECURITY_SETTINGS_CREATE.value) {
      return 'SECURITY_SETTINGS_CREATE'
    }
    if (this.value === ManagerRight.SECURITY_SETTINGS_DELETE.value) {
      return 'SECURITY_SETTINGS_DELETE'
    }
    if (this.value === ManagerRight.FIREWALL_RULES_VIEW.value) {
      return 'FIREWALL_RULES_VIEW'
    }
    if (this.value === ManagerRight.FIREWALL_RULES_EDIT.value) {
      return 'FIREWALL_RULES_EDIT'
    }
    if (this.value === ManagerRight.FIREWALL_RULES_CREATE.value) {
      return 'FIREWALL_RULES_CREATE'
    }
    if (this.value === ManagerRight.FIREWALL_RULES_DELETE.value) {
      return 'FIREWALL_RULES_DELETE'
    }
    if (this.value === ManagerRight.BLOCKED_IP_EVENT_LOG_VIEW.value) {
      return 'BLOCKED_IP_EVENT_LOG_VIEW'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!ManagerRight}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!ManagerRight}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!ManagerRight} Created enum
   */
  static fromObject (other) {
    return new ManagerRight(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
ManagerRight.HOSTS_VIEW = new ManagerRight(0 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.HOSTS_EDIT = new ManagerRight(0 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.HOSTS_CREATE = new ManagerRight(0 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.HOSTS_DELETE = new ManagerRight(0 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MODULES_VIEW = new ManagerRight(100 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MODULES_EDIT = new ManagerRight(100 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MODULES_CREATE = new ManagerRight(100 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MODULES_DELETE = new ManagerRight(100 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MODULES_SYSTEM_INFO_VIEW = new ManagerRight(100 + 4)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.TIMEZONES_VIEW = new ManagerRight(200 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.TIMEZONES_EDIT = new ManagerRight(200 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.TIMEZONES_CREATE = new ManagerRight(200 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.TIMEZONES_DELETE = new ManagerRight(200 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CURRENCY_GROUPS_VIEW = new ManagerRight(300 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CURRENCY_GROUPS_EDIT = new ManagerRight(300 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CURRENCY_GROUPS_CREATE = new ManagerRight(300 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CURRENCY_GROUPS_DELETE = new ManagerRight(300 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CURRENCIES_VIEW = new ManagerRight(400 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CURRENCIES_EDIT = new ManagerRight(400 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CURRENCIES_CREATE = new ManagerRight(400 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CURRENCIES_DELETE = new ManagerRight(400 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SYMBOL_GROUPS_VIEW = new ManagerRight(500 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SYMBOL_GROUPS_EDIT = new ManagerRight(500 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SYMBOL_GROUPS_CREATE = new ManagerRight(500 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SYMBOL_GROUPS_DELETE = new ManagerRight(500 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SYMBOLS_VIEW = new ManagerRight(600 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SYMBOLS_EDIT = new ManagerRight(600 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SYMBOLS_CREATE = new ManagerRight(600 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SYMBOLS_DELETE = new ManagerRight(600 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SYMBOLS_SEND_TICKS = new ManagerRight(600 + 4)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SWAPS_IMPORT = new ManagerRight(600 + 5)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SWAPS_EXPORT = new ManagerRight(600 + 6)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.COMMISSION_PROFILES_VIEW = new ManagerRight(700 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.COMMISSION_PROFILES_EDIT = new ManagerRight(700 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.COMMISSION_PROFILES_CREATE = new ManagerRight(700 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.COMMISSION_PROFILES_DELETE = new ManagerRight(700 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MARKUP_PROFILES_VIEW = new ManagerRight(800 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MARKUP_PROFILES_EDIT = new ManagerRight(800 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MARKUP_PROFILES_CREATE = new ManagerRight(800 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MARKUP_PROFILES_DELETE = new ManagerRight(800 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MANAGER_GROUPS_VIEW = new ManagerRight(900 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MANAGER_GROUPS_EDIT = new ManagerRight(900 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MANAGER_GROUPS_CREATE = new ManagerRight(900 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MANAGER_GROUPS_DELETE = new ManagerRight(900 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MANAGERS_VIEW = new ManagerRight(1000 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MANAGERS_EDIT = new ManagerRight(1000 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MANAGERS_CREATE = new ManagerRight(1000 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MANAGERS_DELETE = new ManagerRight(1000 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.PLATFORMS_VIEW = new ManagerRight(1100 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.PLATFORMS_EDIT = new ManagerRight(1100 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.PLATFORMS_CREATE = new ManagerRight(1100 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.PLATFORMS_DELETE = new ManagerRight(1100 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ACCOUNT_GROUPS_VIEW = new ManagerRight(1200 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ACCOUNT_GROUPS_EDIT = new ManagerRight(1200 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ACCOUNT_GROUPS_CREATE = new ManagerRight(1200 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ACCOUNT_GROUPS_DELETE = new ManagerRight(1200 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ACCOUNTS_VIEW = new ManagerRight(1300 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ACCOUNTS_EDIT = new ManagerRight(1300 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ACCOUNTS_CREATE = new ManagerRight(1300 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ACCOUNTS_DELETE = new ManagerRight(1300 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ACCOUNTS_DEPOSIT = new ManagerRight(1300 + 4)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ACCOUNTS_CREDIT = new ManagerRight(1300 + 5)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ACCOUNTS_TRANSFER_MONEY = new ManagerRight(1300 + 6)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ACCOUNTS_PERFORM_ROLLOVER = new ManagerRight(1300 + 7)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ACCOUNTS_PERSONAL_DETAILS_VIEW = new ManagerRight(1300 + 8)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ORDERS_VIEW = new ManagerRight(1400 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ORDERS_EDIT = new ManagerRight(1400 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ORDERS_CREATE = new ManagerRight(1400 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ORDERS_DELETE = new ManagerRight(1400 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ORDERS_ADJUST_DIVIDENDS = new ManagerRight(1400 + 4)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.ORDERS_SPLIT = new ManagerRight(1400 + 5)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.POSITIONS_VIEW = new ManagerRight(1500 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.POSITIONS_EDIT = new ManagerRight(1500 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.POSITIONS_CREATE = new ManagerRight(1500 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.POSITIONS_DELETE = new ManagerRight(1500 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.TRADE_HISTORY_VIEW = new ManagerRight(1600 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.TRADE_HISTORY_EDIT = new ManagerRight(1600 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.TRADE_HISTORY_DELETE = new ManagerRight(1600 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CALENDAR_RULES_VIEW = new ManagerRight(1800 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CALENDAR_RULES_EDIT = new ManagerRight(1800 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CALENDAR_RULES_CREATE = new ManagerRight(1800 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CALENDAR_RULES_DELETE = new ManagerRight(1800 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.OFF_TIME_RULES_VIEW = new ManagerRight(1900 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.OFF_TIME_RULES_EDIT = new ManagerRight(1900 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.OFF_TIME_RULES_CREATE = new ManagerRight(1900 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.OFF_TIME_RULES_DELETE = new ManagerRight(1900 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.TRADING_ROUTE_RULES_VIEW = new ManagerRight(2000 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.TRADING_ROUTE_RULES_EDIT = new ManagerRight(2000 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.TRADING_ROUTE_RULES_CREATE = new ManagerRight(2000 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.TRADING_ROUTE_RULES_DELETE = new ManagerRight(2000 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SYMBOL_MAPS_VIEW = new ManagerRight(2100 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SYMBOL_MAPS_EDIT = new ManagerRight(2100 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SYMBOL_MAPS_CREATE = new ManagerRight(2100 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SYMBOL_MAPS_DELETE = new ManagerRight(2100 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.GATEWAYS_VIEW = new ManagerRight(2200 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.GATEWAYS_EDIT = new ManagerRight(2200 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.GATEWAYS_CREATE = new ManagerRight(2200 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.GATEWAYS_DELETE = new ManagerRight(2200 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.DATA_FEEDS_VIEW = new ManagerRight(2300 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.DATA_FEEDS_EDIT = new ManagerRight(2300 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.DATA_FEEDS_CREATE = new ManagerRight(2300 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.DATA_FEEDS_DELETE = new ManagerRight(2300 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MAIL_SERVERS_VIEW = new ManagerRight(2400 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MAIL_SERVERS_EDIT = new ManagerRight(2400 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MAIL_SERVERS_CREATE = new ManagerRight(2400 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MAIL_SERVERS_DELETE = new ManagerRight(2400 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.TICK_HISTORY_VIEW = new ManagerRight(2500 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CHART_BARS_VIEW = new ManagerRight(2500 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CHART_BARS_EDIT = new ManagerRight(2500 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CHART_BARS_IMPORT = new ManagerRight(2500 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SESSIONS_VIEW = new ManagerRight(2600 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SESSIONS_DROP = new ManagerRight(2600 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.LEVERAGE_PROFILE_VIEW = new ManagerRight(2700 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.LEVERAGE_PROFILE_EDIT = new ManagerRight(2700 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.LEVERAGE_PROFILE_CREATE = new ManagerRight(2700 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.LEVERAGE_PROFILE_DELETE = new ManagerRight(2700 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MARGIN_RATE_PROFILE_VIEW = new ManagerRight(2800 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MARGIN_RATE_PROFILE_EDIT = new ManagerRight(2800 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MARGIN_RATE_PROFILE_CREATE = new ManagerRight(2800 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.MARGIN_RATE_PROFILE_DELETE = new ManagerRight(2800 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.NEWS_PROFILE_VIEW = new ManagerRight(2900 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.NEWS_PROFILE_EDIT = new ManagerRight(2900 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.NEWS_PROFILE_CREATE = new ManagerRight(2900 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.NEWS_PROFILE_DELETE = new ManagerRight(2900 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.EMAIL_TEMPLATE_VIEW = new ManagerRight(3000 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.EMAIL_TEMPLATE_EDIT = new ManagerRight(3000 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.EMAIL_TEMPLATE_CREATE = new ManagerRight(3000 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.EMAIL_TEMPLATE_DELETE = new ManagerRight(3000 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.JOURNAL_VIEW = new ManagerRight(3100 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CLIENT_REPORTS_VIEW = new ManagerRight(3200 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.CLIENT_REPORTS_SEND = new ManagerRight(3200 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.PERSON_VIEW = new ManagerRight(3300 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.PERSON_EDIT = new ManagerRight(3300 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.PERSON_CREATE = new ManagerRight(3300 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SECURITY_SETTINGS_VIEW = new ManagerRight(3400 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SECURITY_SETTINGS_EDIT = new ManagerRight(3400 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SECURITY_SETTINGS_CREATE = new ManagerRight(3400 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.SECURITY_SETTINGS_DELETE = new ManagerRight(3400 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.FIREWALL_RULES_VIEW = new ManagerRight(3500 + 0)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.FIREWALL_RULES_EDIT = new ManagerRight(3500 + 1)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.FIREWALL_RULES_CREATE = new ManagerRight(3500 + 2)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.FIREWALL_RULES_DELETE = new ManagerRight(3500 + 3)
// noinspection PointlessArithmeticExpressionJS
ManagerRight.BLOCKED_IP_EVENT_LOG_VIEW = new ManagerRight(3600 + 0)

export { ManagerRight };

/**
 * Fast Binary Encoding ManagerRight field model
 */
class FieldModelManagerRight extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelManagerRight}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the value
   * @this {!FieldModelManagerRight}
   * @param {ManagerRight=} defaults Default value, defaults is new ManagerRight()
   * @returns {!ManagerRight} Result value
   */
  get (defaults = new ManagerRight()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new ManagerRight(this.readInt32(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelManagerRight}
   * @param {!ManagerRight} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeInt32(this.fbeOffset, value.value)
  }
}

export { FieldModelManagerRight };
